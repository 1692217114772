<template>
	<main class="vcc-main vcc-main__kb">
		<h1 class="visually-hidden">База знаний</h1>
		<h2 class="heading heading__hr hr__bold">Нормативно-правовые акты</h2>
		<section class="materials-main">
			<section class="kb--aside">
				<nav class="kb--nav">
					<ul class="kb-nav--list">
						<li><router-link :to="{name: 'Materials'}" class="link__menu">Материалы</router-link></li>
						<li><router-link :to="{name: 'Rlas'}" class="link__menu">Нормативно-правовые акты</router-link></li>
						<li><router-link :to="{name: 'BusinessTypes'}" class="link__menu">Виды деятельности</router-link></li>
					</ul>
				</nav>
				<small-email-form type="rla"/>
			</section>
			<section class="list-wth-filters">
				<section class="search-section">
					<form @submit.prevent="">
						<label for="webinars-search" class="label__input">Поиск по названию</label>
						<input type="text" name="webinars-search" id="webinars-search" class="input" placeholder="Начните вводить название" v-model="search_req" list="webinars-titles">
						<div class="input-wrapper" v-show="false">
							<label for="webinars-filter">Фильтр по видам деятельности</label>
							<select name="webinars-filter" id="webinars-filter" class="select">
								<option value="Ценообразование">Ценообразование</option>
							</select>
							<span class="expand-icon"></span>
						</div>
					</form>
					<ul class="filters-wrapper" v-show="false">
						<li class="link__btn btn__filter btn__filter-name">
							Ценообразование
							<button type="button" class="btn__close"></button>
						</li>
					</ul>
					<section class="sorting-section">
						<hr class="hr__blue">
						<div class="sorting--info">
							<dl class="dl__theme sorting-section--dl">
								<dt>Соответствует запросу</dt>
								<dd><b>{{filteredItems.length}}</b></dd>
							</dl>
							<dl class="dl__theme sorting-section--dl">
								<dt>Всего в базе</dt>
								<dd><b>{{getRlas.length}}</b></dd>
							</dl>
							<dl class="dl__theme dl--filter sorting-section--dl">
								<dt><b>Сортировать</b></dt>
								<dd>
									<div class="filter-group">
										<input type="radio" name="order" id="asc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="false">
										<label for="asc" class="sorting-by">с начала</label>
									</div>
									<div class="filter-group">
										<input type="radio" name="order" id="desc" class="control-radio visually-hidden" v-model="filter.sort_by" :value="true">
										<label for="desc" class="sorting-by">с конца</label>
									</div>
									<div :class="['filter-group', {'chrome-date': isChrome }]">
										<input type="date" name="sort-date" id="filter-date" :class="['sort-date', 'visually-hidden', 'date-visually-hidden', {'date-not-empty': filter.date != null}]" v-model="filter.date">
										<label for="filter-date" class="sorting-by">
											по дате
											<svg width="8" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 .66a.57.57 0 0 1-.21.45L4.36 3.87a.57.57 0 0 1-.73 0L.21 1.01A.57.57 0 0 1 .94.13L4 2.7 7.06.22A.57.57 0 0 1 8 .66Z" fill="#9C9D9D"/></svg>
										</label>
									</div>
								</dd>
							</dl>
						</div>
					</section>
				</section>
				<ul class="kb--list" v-if="filteredItems.length > 0">
					<li v-for="material, key in paginatedItems" :key="'rla-'+key">
						<vcc-kb-card :card-info="material" card-type="rla" />
					</li>
				</ul>
				<div class="no-webinars" v-else>
					<h4>По вашему запросу не нашлось нормативно-правовых актов</h4>
					<p>Попробуйте применить другие фильтры или другой запрос.</p>
				</div>
				<pagination-pages
					:current-page.sync="currentPage"
					:items-count="filteredItems.length"
					:pages-count="pageCount"
					:btn-type="isMobile ? 'arrows' : 'word'"
					@prevPage="prevPage"
					@nextPage="nextPage"
				/>
			</section>
		</section>
	</main>
</template>

<script>
import deviceWidth from '@/mixins/deviceWidth.js';
import SmallEmailForm from '@/components/forms/SmallEmailForm.vue'
import KbCard from '@/components/cards/KbCard.vue'
import { mapGetters, mapActions } from 'vuex'
import PaginationPages from '@/components/common/PaginationPages.vue'
export default {
	data() {
		return {
			items_per_page: 9,
			page_number: this.page || 1,
			search_req: null,
			filter: {
				date : null,
				sort_by: true,
			},
		}
	},
	mixins: [deviceWidth],
	props:['page'],
	components: {
		'vcc-kb-card': KbCard,
		'small-email-form': SmallEmailForm,
		PaginationPages
	},
	computed: {
		...mapGetters(['getRlas']),
		paginatedItems() {
			const start = (this.currentPage - 1) * this.items_per_page,
					end = start + this.items_per_page;
			return this.filteredItems.slice(start, end);
		},
		pageCount() {
			let l = this.filteredItems.length,
				s = this.items_per_page;
			return Math.ceil(l / s);
		},
		currentPage() {
			if(this.page > 0) {
				return Number(this.page)
			} else {
				return Number(this.page_number)
			}
		},
		filteredItems() {
			const rlas = this.getRlas
			const searched_rlas = this.search_req != null ? rlas.filter(item => item.title.toLowerCase().includes(this.search_req.toLowerCase())) : rlas
			return searched_rlas.sort((a, b) => {
				if(this.filter.sort_by) {
					return b.id - a.id
				} else {
					return a.id - b.id
				}
			})
			.filter((item) => {
				if(this.filter.date !== null && this.filter.date !== '') {
					return this.isEventToday(item.created, this.filter.date) ? item : false
				} else {
					return item
				}
			})
		}
	},
	methods: {
		...mapActions(['fetchAllRlas']),
		prevPage() {
			this.$router.push({
				name: 'RlasPage',
				params: {
					page: this.currentPage-1
				}
			})
		},
		nextPage() {
			this.$router.push({
				name: 'RlasPage',
				params: {
					page: this.currentPage+1
				}
			})
		},
		atStartPage() {
			this.$router.push({
				name: 'RlasPage',
				params: {
					page: 1
				}
			})
		},
		atEndPage() {
			this.$router.push({
				name: 'RlasPage',
				params: {
					page: this.pageCount()
				}
			})
		},
	},
	created() {
		this.fetchAllRlas()
	}

}
</script>

<style>

</style>
